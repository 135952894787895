// import axios from 'axios'
// import request from '../assets/js/axios'
// const _json = require('../../public/api.json')
// import _json from '../../public/api.js'
import store from '../store'
const api = {
  // 矩阵数据
  getMatrix: function (callback) {
  /*  request.get('./api.json').then(res => {
      if (callback) {
        const _data = {
          code: 200,
          data: res.matrix
        }
        return callback(_data)
      }
    }) */
    if (callback) {
      const _data = {
        code: 200,
        data: store.state.data.matrix
      }
      return callback(_data)
    }
  },
  // 获取矩阵文档
  getMatrixDocument: function (callback) {
    if (callback) {
      const _data = {
        code: 200,
        data: store.state.data.matrixDocument
      }
      return callback(_data)
    }
    /*    request.get('./api.json').then(res => {
      if (callback) {
        const _data = {
          code: 200,
          data: res.matrixDocument
        }
        return callback(_data)
      }
    }) */
  },
  // 获取关于我们
  getAboutUs: function (callback) {
    if (callback) {
      const _data = {
        code: 200,
        data: store.state.data.aboutUs
      }
      return callback(_data)
    }
  /*  request.get('./api.json').then(res => {
      if (callback) {
        const _data = {
          code: 200,
          data: res.aboutUs
        }
        return callback(_data)
      }
    }) */
  }
}

export default api
