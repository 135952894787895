<template>
  <div class="homeWrap">
    <div class="matrixbannerimg">
      <img src="@/assets/images/banner.png" alt="" class="matrixBg">
      <div class="matrixbox">
        <MyHeader></MyHeader>
        <div class="matrixBanner">
          <p>云安全攻防矩阵</p>
          <span>
              随着云计算技术与产业的不断发展，云计算技术在多个领域得到了应用，更多的企业采用云计算实现数字化转型。虽然云计算拥有着很多优势，但也带来了全新的安全挑战，云上安全问题日益成为了人们关注的重点。腾讯安全云鼎实验室针对云上安全所面临的威胁以及攻击技术进行整理，从实战角度出发，围绕云原生务场景，通过全方面分析攻击者战术与手段，推出云安全攻防矩阵，旨在助力企业知攻知防，并于9月26日，2021首届-西部云安全峰会上发布。该矩阵共分为初始访问、执行、持久化、权限提升、防御绕过、窃取凭据、探测、横向运动、影响这九大阶段，每个阶段中都包含了多种用以实现此阶段能力的攻击技术。通过了解这些攻击技术，可以有效的帮助开发以及运维人员识别危险与风险，从而有效的保障云上资产安全。
          </span>
          <img src="../assets/images/mouse.png" alt="" class="matrixBannermouse">
        </div>
      </div>
      <div class="matrixBannerCloud">
        <img src="../assets/images/cloudleft.png" alt="" class="matrixBannerCloud1 myAction">
        <img src="../assets/images/cloudbetween.png" alt="" class="matrixBannerCloud2 myAction">
        <img src="../assets/images/cloudright.png" alt="" class="matrixBannerCloud3 myAction">
      </div>
    </div>
    <div class="matrixMain">
      <div :class="['matrixContent',{test:true ,testenter: testShow1}]" ref="testref1">
        <div class="matrixContentTitle">
          <img src="../assets/images/titleadornmentl.png" alt="" style="top:-20px">
          <span>云安全攻防矩阵V3.0</span>
          <img src="../assets/images/titleadornmentr.png" alt="">
        </div>
        <div class="matrixContentMain">
          <div style="display:flex">
            <div class="matrixMainTitle" v-for="(item,ind) in titleDate" :key="item.id">
              <div>
                <p>{{item.title}}</p>
                <ul v-for="(sitem,index) in item.titleList" :key="sitem.sonId" class="matrixMainTitle-son">
                  <!-- <div> -->
                  <a v-for="(ritem,indexh) in sitem.navTitle" :key="ritem.id" @click="gotoMatrix(ind,index,indexh)">
                    <span style="color: #7699FF;" id="clickBackground" v-if="index == 0" :style="{'border-bottom':(indexh == sitem.navTitle.length-1 ? 'none' : '1px solid rgba(78,101,255,0.20)')}">{{ritem.descArr}}</span >
                    <span style="color: #BFA6FF;" id="clickBackground" v-if="index == 1" :style="{'border-bottom':(indexh == sitem.navTitle.length-1 ? 'none' : '1px solid rgba(78,101,255,0.20)')}">{{ritem.descArr}}</span>
                    <span style="color: #C3F4FF;" id="clickBackground" v-if="index == 2" :style="{'border-bottom':(indexh == sitem.navTitle.length-1 ? 'none' : '1px solid rgba(78,101,255,0.20)')}">{{ritem.descArr}}</span>
                    <span style="color: #ceb66b;" id="clickBackground" v-if="index == 3" :style="{'border-bottom':(indexh == sitem.navTitle.length-1 ? 'none' : '1px solid rgba(78,101,255,0.20)')}">{{ritem.descArr}}</span>
                    <span style="color: #B9CBFF;" id="clickBackground" v-if="index == 4" :style="{'border-bottom':(indexh == sitem.navTitle.length-1 ? 'none' : '1px solid rgba(78,101,255,0.20)')}">{{ritem.descArr}}</span>
                  </a>
                  <!-- </div> -->
                </ul>
              </div>
              <img src="../assets/images/arrow.png" alt="" v-if="titleDate.length != item.id">
            </div>
          </div>
          <div class="matrixContentRemark">
            <span style="color: #7699FF;"><i style="background-color: #7699FF;"></i>云服务器攻击方式</span>
            <span style="color: #BFA6FF;"><i style="background-color: #BFA6FF;"></i>容器攻击方式</span>
            <span style="color: #C3F4FF;"><i style="background-color: #C3F4FF;"></i>cos存储攻击方式</span>
            <span style="color: #ceb66b;"><i style="background-color: #ceb66b;"></i>Serverless攻击方式</span>
            <span style="color: #B9CBFF;"><i style="background-color: #B9CBFF;"></i>共同攻击方式</span>
          </div>
        </div>
      </div>
      <div class="matrixSolved">
        <div class="matrixSolvedMain">
          <div :class="{test:true ,testenter: testShow2}" ref="testref2">
            <div class="matrixContentTitle">
              <img src="../assets/images/titleadornmentl.png" alt="" style="top:-20px">
              <span>解决的问题</span>
              <img src="../assets/images/titleadornmentr.png" alt="">
            </div>
            <p>通过漏洞数据、攻击事件，抽象出攻击模型</p>
            <div class="solvedContent">
              <div class="solvedContentList">
                <div>
                  <img src="../assets/images/solved1.png" alt="">
                  <span>云原生安全威胁，基于云原生，进行渗透测试，发现安全威胁</span>
                </div>
                <div>
                  <img src="../assets/images/solved2.png" alt="">
                  <span>Web/Api安全威胁，因Web/Api是目前实现云业务的最佳方式，其漏洞将直接威胁到云</span>
                </div>
              </div>
              <div class="solvedContentList">
                <div>
                  <img src="../assets/images/solved3.png" alt="">
                  <span>产品业务逻辑威胁，错综复杂的产品业务逻辑仍是云安全的一大威胁</span>
                </div>
                <div>
                  <img src="../assets/images/solved4.png" alt="">
                  <span>云计算安全威胁，从宏观角度看云到底面临哪些安全问题</span>
                </div>
              </div>
            </div>
          </div>
          <div :class="['solvedbottom',{test:true ,testenter: testShow3}]" ref="testref3">
            <h3>全方位了解攻击手段及途径，关注整个云生态</h3>
            <p style="color: #9095A5">共分为初始访问、执行、持久化、权限提升、防御绕过、窃取凭据、探测、横向运动、影响九大阶段，每个都包含了多种用以实现此阶段能力的攻击技术。
              覆盖了更多维度的攻防流程和对象，从识别访问到探测移动，再到持久防御作战，关注整个云生态的安全稳定。</p>
            <div class="solvedIcon">
            <span @click="gotoMatrix(0,0,0)">
              <img src="../assets/images/icon1.png" alt="">
              <b>初始访问</b>
            </span>
            <span @click="gotoMatrix(1,0,0)">
              <img src="../assets/images/icon2.png" alt="">
              <b>执行</b>
            </span>
            <span @click="gotoMatrix(2,0,0)">
              <img src="../assets/images/icon7.png" alt="">
              <b>持久化</b>
            </span>
            <span @click="gotoMatrix(3,0,0)">
              <img src="../assets/images/icon8.png" alt="">
              <b>权限提升</b>
            </span>
            <span @click="gotoMatrix(4,0,0)">
              <img src="../assets/images/icon5.png" alt="">
              <b>防御绕过</b>
            </span>
            <span @click="gotoMatrix(5,0,0)">
              <img src="../assets/images/icon6.png" alt="">
              <b>窃取凭据</b>
            </span>
            <span @click="gotoMatrix(6,0,0)">
              <img src="../assets/images/icon3.png" alt="">
              <b>探测</b>
            </span>
            <span @click="gotoMatrix(7,0,0)">
              <img src="../assets/images/icon4.png" alt="">
              <b>横向移动</b>
            </span>
            <span style="margin-right:0;" @click="gotoMatrix(8,0,0)">
              <img src="../assets/images/icon9.png" alt="">
              <b>影响</b>
            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="matrixAboutMe">
        <img src="../assets/images/aboutme.png" alt="" style="width:100%">
        <div class="matrixAboutMeContent">
          <span>云安全攻防矩阵，守护云上安全</span>
          <p>从实战角度出发，助力企业知攻知防</p>
          <button @click="aboutMe" style="cursor: pointer;">关于我们</button>
        </div>
      </div>
      <MyFooter></MyFooter>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Index',
  components: {
  },
  data () {
    return {
      titleDate: [],
      currentscroll: 0,
      testShow1: false,
      testShow2: false,
      testShow3: false,
      timer: null
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handlescroll)
    this.handlescroll()
    this.$api.getMatrix(res => {
      this.titleDate = res.data
    })
  },
  methods: {
    handlescroll () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        const documentHeight = document.documentElement.clientHeight || document.body.clientHeight
        this.currentscroll = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
        // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // let ViewportTop = Element.absoluteTop - scrollTop;
        if (this.$refs.testref1 && this.$refs.testref2 && this.$refs.testref3) {
          // if (this.currentscroll >= this.$refs.testref1.offsetTop - 800) {
          //   this.testShow1 = true
          // }
          // if (this.currentscroll >= this.$refs.testref2.offsetTop - 1000) {
          //   this.testShow2 = true
          // }
          // if (this.currentscroll >= this.$refs.testref3.offsetTop - 1200) {
          //   this.testShow3 = true
          // }
          if (documentHeight - this.$refs.testref1.getBoundingClientRect().top > -50) {
            this.testShow1 = true
          }
          if (documentHeight - this.$refs.testref2.getBoundingClientRect().top > -50) {
            this.testShow2 = true
          }
          if (documentHeight - this.$refs.testref3.getBoundingClientRect().top > -50) {
            this.testShow3 = true
          }
        }
      }, 200)
    },
    aboutMe () {
      this.$router.push({ path: '/AboutUs' })
    },
    //  跳转矩阵文档
    gotoMatrix (ind, index, sindex) {
      const str = `${ind}-${index}-${sindex}`
      this.$router.push({
        path: '/MatrixDoc',
        query: {
          action: str
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.homeWrap{
  margin: 0 auto;
  color: #FFF;
}
.matrixbox{
  margin: 0 auto;
}
.matrixbannerimg{
  width: 1200px;
  margin: 0 auto;
  position: relative;
  //background: url("../assets/images/banner.png") no-repeat 0px 0px;
  //background-size:  1440px 558px;
  .matrixBg{
    position: absolute;
    width: 1440px;
  }
  .matrixBannerCloud{
    .matrixBannerCloud1{
      position: absolute;
      top: 21%;
      right: 34%;
      width:50px;
      height:46px
    }
    .matrixBannerCloud2{
      position: absolute;
      top: 41%;
      right: 13%;
      width:38px;
      height:35px
    }
    .matrixBannerCloud3{
      position: absolute;
      top: 26%;
      right: 3.5%;
      width:54px;
      height:50px
    }
    .myAction{
      animation: floatDiv 4s ease 0s infinite ;
    }
    @keyframes floatDiv{
      0%{
        transform: translateY(0px);
      }
      50%{
        transform: translateY(6px);
      }
      100%{
        transform: translateY(px);
      }
    }
  }
}
.testenter{
  transform: translateY(0%) ! important; //滚动后的位置
  opacity: 1!important; //滚动后显现
  transition: all 1s ease;
}
.test{
  transform: translateY(200px); //滚动前的位置
  opacity: 0; //滚动前设置透明隐藏।
}
.matrixBanner{
  height: 668px;
  padding-top: 181px;
  box-sizing: border-box;
  position: relative;
  p{
    font-family: TencentSansW7;
    font-size: 40px;
    color: #FFFFFF;
    margin-bottom: 19px;
  }
  span{
    width: 610px;
    height: 72px;
    display: block;
    font-family: TencentSansW3;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 24px;
  }
  .matrixBannermouse{
    width: 24px;
    height: 37px;
    display: inline-block;
    position: absolute;
    top: 84%;
    left: 0;
    right: 0;
    margin: auto;
    padding-bottom: 47px;
  }
}
// .matrixMain{
// }
.matrixContentTitle{
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-top: 80px;
  margin-bottom: 74px;
  img{
    width: 46px;
    height: 46px;
    position: relative;
  }
  span{
    font-family: TencentSansW7;
    font-size: 34px;
    color: #FFFFFF;
  }
}
.matrixContent{
  .matrixContentMain{
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    .matrixMainTitle{
      position: relative;
      p{
        text-align: center;
        font-family: TencentSansW7;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 28px;
        padding: 18px 29px;
        box-sizing: border-box;
        width: 122px;
        height: 65px;
        display: inline-block;
        background: rgba(2,24,85,0.20);
        box-shadow: inset 0 1px 2px 0 rgba(255,255,255,0.10);
        border-radius: 4px;
        margin-right: 14px;
      }
      img{
        width: 32px;
        height: 22px;
        position: absolute;
        left: 115px;
        top: 24px;
      }
    }
    .matrixContentRemark{
      margin-top: 32px;
      padding-bottom: 18px;
      margin-bottom: 40px;
      text-align: right;
      border-bottom: 1px solid rgba(78,101,255,0.40);
      span{
        margin-right: 42px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        line-height: 20px;
        i{
          margin-right: 11px;
          width: 12px;
          height: 12px;
          display: inline-block;
        }
      }
    }
  }
  .matrixMainTitle-son{
    background: rgba(20,31,60,0.75);
    border-radius: 4px;
    margin: 0 14px 5px 0;
    box-sizing: border-box;
    font-size: 12px !important;
    text-align: center;
    a{
      display: inline-block;
      width: 122px;
      padding: 0 11px;
      cursor: pointer;
      span{
        display: inline-block;
        padding: 15px 14px;
        font-size: 12px !important;
        line-height: 20px;
      }
    }
    a:hover{
      background: url(../assets/images/click.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
.matrixSolvedMain{
  width: 1200px;
  margin: 0 auto;
  p{
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #E6EDFF;
    text-align: center;
    margin-bottom: 50px;
  }
  .solvedContent{
    margin: 0 auto;
    .solvedContentList{
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #E6EDFF;
      line-height: 24px;
      margin: 0 42px;
      color: #9095A5;
      img{
        width: 155px;
      }
      span{
        display: inline-block;
        width: 320px;
        height: 50%;
        flex: 1;
      }
    }
  }
  .solvedbottom{
    text-align: center;
    margin: 62px 0;
    h3{
      font-size: 22px;
      color: #E6EDFF;
      margin-bottom: 30px;
    }
    p{
      color: #E6EDFF;
      font-size: 14px;
      line-height: 24px;
      width: 950px;
      margin: 0 auto;
      margin-bottom: 70px;
    }
  }
  .solvedIcon{
    padding-bottom: 120px;
    span{
      display: inline-block;
      width: 60px;
      margin-right: 75px;
      cursor:pointer;
    }
    img{
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
    }
    b{
      font-size: 14px;
      color: #E6EDFF;
      text-align: center;
    }
  }
}
.matrixAboutMe{
  position: relative;
  text-align: center;
  .matrixAboutMeContent{
    color: #fff;
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    margin: auto;
    span{
      font-size: 24px;
      color: #FFFFFF;
      line-height: 36px;
      margin-bottom: 30px;
      display: inline-block;
    }
    p{
      opacity: 0.8;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 20px;
      margin-bottom: 40px;
    }
    button{
      width: 144px;
      height: 40px;
      background: #005AFF;
      border-radius: 4px;
      border: none;
      color: #fff;
    }
  }
}

#clickBackground {
}
</style>
