import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
// import router from '../router'
// create an axios instance
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 30000
  // withCredentials: true, // send cookies when cross-domain requests
  // request timeout
})
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 储存全局token
  const token = store.state.user.token
  if (token) {
    config.headers.token = store.state.user.token
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  Message.error(error)
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // const status = response.status
  // 对响应数据做点什么
  return response.data
}, function (error) {
  Message.error(error.response.data.msg)
  return Promise.reject(new Error(error))
})

export default instance
