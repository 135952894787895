<template>
<div id="MatrixDoc">
  <div class="MatrixDocWrap">
    <MyHeader></MyHeader>
    <div class="MatrixDocBox">
      <el-row class="tac">
        <el-col :span="7" class="MatrixDocBoxLeft">
          <h5 class="f18 bold">矩阵文档</h5>
          <el-menu
            class="el-menu-vertical-demo"
            :default-active="actionIndex"
            text-color="#8A9DCF"
            active-text-color="#fff"
            :unique-opened="true"
            @open="handleOpen"
            @select="handSelect"
            @close="handleClose" v-if="navData">
            <el-submenu v-for="(item,index) in navData" :index="String(index)" :key="item.id">
              <template slot="title">
                <span class="f16 bold">{{ item.title }}</span>
              </template>
              <el-menu-item-group v-for="(sitem,sindex) in item.titleList" :key="sindex">
                <template slot="title">
                  <span class="f14" style="color: #7699FF;">{{ sitem.secondTitle }}</span>
                </template>
                <el-menu-item  v-for="(navitem,navindex) in sitem.navTitle" :key="navitem.sonId" :index="`${index}-${sindex}-${navindex}`">{{ navitem.descArr }}</el-menu-item>
              </el-menu-item-group>

            </el-submenu>

          </el-menu>
        </el-col>

        <el-col :span="17">
          <div class="matrixDocuWrap" v-if="matrixObj">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item v-for="(item,index) in matrixObj.headerNav" :class="index===2?'action':''" :key="index">{{ item }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="matrixTitle">
              {{matrixObj.headerNav[2]}}
            </div>
            <div class="f12" style="color: #8A9DCF;margin-bottom: 31px;">
              <span>更新时间：{{matrixObj.updateTime}}</span>
              <span style="margin-left: 26px"><i class="el-icon-view"></i> 浏览次数：{{ matrixObj.viewCount }}</span>
            </div>
            <div>
              <p style="line-height: 24px;">{{ matrixObj.info }}</p>
            </div>
            <div v-for="(item, sindex) in matrixObj.secondList" class="matrixContent" :key="sindex">
              <div class="secondTitle f16">
                <i class="shu"></i> <span style="  vertical-align: top">{{ item.secondTitle }}</span>
              </div>
             <div style="margin-top: 12px;line-height: 24px;"> {{ item.secondInfo }}</div>
            </div>

          </div>
          <div class="matrixDocuWrap"  v-if="!this.matrixObj">暂无数据</div>
        </el-col>
      </el-row>

    </div>
  </div>
  <MyFooter></MyFooter>
</div>
</template>

<script>
export default {
  name: 'MatrixDoc',
  data () {
    return {
      matrixList: null, // 矩阵文档数组
      matrixObj: null, // 矩阵文档
      navData: null, // 导航栏的数据
      actionIndex: '0-0-0' // 0-0-0
    }
  },
  mounted () {
    // 此处true需要加上，不加滚动事件可能绑定不成功
    // window.addEventListener('scroll', this.handleScroll, true)
    this.$api.getMatrix(res => {
      if (res.code == 200) {
        this.navData = res.data
      }
      this.$api.getMatrixDocument(res => {
        if (res.code == 200) {
          this.matrixList = res.data
        }
        if (this.$route.query.action) {
          this.actionIndex = this.$route.query.action
        }
        this.handSelect(this.actionIndex, [this.actionIndex.split('-')[0], this.actionIndex])
      })
    })
  },
  methods: {
    handleOpen (key, keyPath) {
      this.gototop()
    },
    handleClose (key, keyPath) {
    },
    handSelect (key, keyPath) {
      this.gototop()
      this.matrixObj = null
      const keystr = key.split('-')
      const sonid = this.navData[keystr[0]].titleList[keystr[1]].navTitle[keystr[2]].sonId
      this.matrixList.map(obj => {
        if (obj.sonId == sonid) {
          this.matrixObj = obj
        }
      })
      if (!this.matrixObj) {
        // this.$message.error('没有对应数据哦')
        return
      }
      // 头部的面包屑
      this.matrixObj.headerNav = []
      this.matrixObj.headerNav.push(this.navData[keyPath[0]].title)
      this.matrixObj.headerNav.push(this.navData[keystr[0]].titleList[keystr[1]].secondTitle)
      this.matrixObj.headerNav.push(this.navData[keystr[0]].titleList[keystr[1]].navTitle[keystr[2]].descArr)
    },
    gototop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>

<style scoped lang="less">
#MatrixDoc{
  color: #fff;
  width: 100%;
  min-height: 100vh;
  .MatrixDocWrap{
    width: 1200px;
    margin: 0 auto;
    .MatrixDocBox{
      padding: 30px 0;
      margin-top: 47px;
      .MatrixDocBoxLeft{
        padding: 30px;
        background: linear-gradient(#0C193F,#0F131E);
        /deep/ .el-submenu__title:hover{
          background: rgba(0,90,255,0.60) !important;
        }
        /deep/ .el-menu-vertical-demo{
          border-right: none;
          margin-right: 30px;
          background-color:transparent;
        }
        /deep/ .el-menu--inline{
          border-left: 1px solid #2F3E99;
          background-color:transparent;
        }
        /deep/ .el-menu-item{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #8A9DCF;
          &:hover{
            background-color: rgba(0,90,255,0.60) !important;
          }
          &:focus{
            background-color: rgba(0,90,255,0.60) !important;
          }
        }
        /deep/ .el-menu-item-group{
          /deep/ .is-active{
            background: rgba(0,90,255,0.60) !important;
          }
        }
        /deep/ .el-menu-item-group__title{
          color: #7699FF;
        }
      }

    }
  }

  .matrixDocuWrap{
    padding: 30px 50px 41px;
    color: #B0BBD3;
    background: linear-gradient(#071F57,#111525 80%);
    min-height: 80vh;
    /deep/ .el-breadcrumb{
      padding-bottom: 12px;
      border-bottom: 1px solid #2F3E99;
      margin-bottom: 41px;
    }
    /deep/ .el-breadcrumb__inner{
      color: #B0BBD3;
      font-size: 12px;
    }
    .action {
      /deep/ .el-breadcrumb__inner{
        color: #fff;
        font-size: 12px;
      }
    }
    .matrixTitle{
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 7px;
    }
    .secondTitle{
      color: #7699FF;

    }
    .shu{
      width: 2px;
      height:22px;
      display: inline-block;
      margin-right: 14px;
      background: #7699FF;
    }
    .matrixContent{
      margin: 28px 0 20px;
    }
  }
}
</style>
