<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    meta: [
      { name: 'keywords', content: '云原生安全,云鼎实验室,云安全,容器&k8s安全,云安全攻防矩阵,威胁矩阵,云上攻防' },
      {
        name: 'description',
        content: '随着云计算技术与产业的不断发展，云计算技术在多个领域得到了应用，更多的企业采用云计算实现数字化转型。' +
          '虽然云计算拥有着很多优势，但也带来了全新的安全挑战，云上安全问题日益成为了人们关注的重点。' +
          '腾讯安全云鼎实验室针对云上安全所面临的威胁以及攻击技术进行整理，从实战角度出发，围绕云原生务场景' +
          '，通过全方面分析攻击者战术与手段，' +
          '推出云安全攻防矩阵.'
      },
      { property: 'og:title', content: '腾讯安全云鼎实验室-云安全攻防矩阵' },
      { property: 'og:type', content: 'security' },
      { property: 'og:url', content: 'https://cloudsec.tencent.com/' },
      {
        property: 'og:description',
        content: '随着云计算技术与产业的不断发展，云计算技术在多个领域得到了应用，更多的企业采用云计算实现数字化转型。' +
          '虽然云计算拥有着很多优势，但也带来了全新的安全挑战，云上安全问题日益成为了人们关注的重点。' +
          '腾讯安全云鼎实验室针对云上安全所面临的威胁以及攻击技术进行整理，从实战角度出发，围绕云原生务场景' +
          '，通过全方面分析攻击者战术与手段，' +
          '推出云安全攻防矩阵.'
      },
      { name: 'baidu-site-verification', content: 'code-mrS8vIzZFD' }
    ]
  }
}
</script>

<style>
#app {
  background: url("./assets/images/background.png") no-repeat;
  background-size: 100% 100%;
  max-width: 2560px;
  min-width: 1230px;
  width: 100%;
  /*width:1900px;*/
  margin: 0 auto;
  overflow: hidden;
}
</style>
