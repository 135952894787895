<template>
  <div>
    <div class="matrixicon">
      <img src="../../assets/images/tencentcloud.png" alt=""> <b></b>
      <img src="../../assets/images/tencentsecurity.png" alt=""> <b></b>
      <img src="../../assets/images/yundinglaboratory.png" alt="">
    </div>
    <div class="matrixfoot">Copyright © 2013 - 2022 Tencent Cloud .  ALL rights reserved · 腾讯科技（深圳）有限公司京公网安备 11010802017518|粤B2-20090059-1| 域名注册服务机构批复文号：京信信管发〔2021〕11号鲁D3-20210001粤通业函〔2018〕268号</div>
  </div>
</template>
<script>
export default {
  name: 'MyFooter'
}
</script>
<style lang="less" scoped>
.matrixicon{
  width: 1200px;
  margin: 0 auto;
  margin-top: 54px;
  text-align: center;
  padding-bottom: 48px;
  border-bottom: solid 1px  rgba(255,255,255,0.20);;
  b{
    display: inline-block;
    width: 1px;
    height: 21px;
    opacity: 0.15;
    background: #FFFFFF;
    position: relative;
    top: 8px;
  }
  img{
    height: 27px;
    margin: 0 22px 0 26px;
  }
}
.matrixfoot{
  height: 60px;
  opacity: 0.6;
  font-weight: Regular;
  text-align: center;
  line-height: 60px;
  padding-bottom: 60px;
  color: #FFFFFF;
}
</style>
