import Vue from 'vue'
import Router from 'vue-router'
import Index from '../views/Index'
import AboutUs from '../views/AboutUs/AboutUs'
import MatrixDoc from '../views/MatrixDoc/MatrixDoc'
const routes = [
  {
    path: '/',
    redirect: '/home',
    component: Index,
    children: [
      {
        path: '/home',
        name: 'Index',
        meta: {
          title: '首页',
          role: 1
        },
        component: Index
      }
    ]
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    meta: {
      title: '关于我们',
      role: 2
    },
    component: AboutUs
  },
  {
    path: '/MatrixDoc',
    name: 'aboutUs',
    meta: {
      title: '矩阵文档',
      role: 3
    },
    component: MatrixDoc
  }
]

Vue.use(Router)
const router = new Router({
  routes,
  mode: 'history'
})
/* {
      path: "*",
      component: Home,
      redirect: "/Home"
    } */
// router.beforeEach((to, from, next) => {
//   next()
// })
// 重复点击菜单报错处理
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    // location.href = process.env.VUE_APP_BASE_URL + '/_logout/?url=' + process.env.VUE_APP_BASE_URL + '/';
  }
})
export default router
