import Vue from 'vue'
import Vuex from 'vuex'
// import router from "./../router";

Vue.use(Vuex)

/* const getType = (data, arr, tree_data) => {
  if (data.children && data.children.length > 0) {
    data.children.forEach(item => {
      arr.push(getType(item, arr, tree_data));
    });
  } else {
    arr.push({ ...data, tree_data });
  }
}; */

/* const getProductAll = data => {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    arr.push({ label: data[i].label, value: data[i].value });
    if (data[i].children && data[i].children.length > 0) {
      arr = [...arr, ...getProductAll(data[i].children)];
    }
  }
  return arr;
}; */

export default new Vuex.Store({
  state: {
    user: {},
    data: ''
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    }

  },
  actions: {},
  modules: {}
})
