<template>
  <div class="aboutUs">
    <div class="aboutUsMain">
      <MyHeader></MyHeader>
      <div class="aboutUsContent">
        <h2>
          <b @click="$router.push('/home')" style="cursor: pointer;">首页</b>
          <i>></i>
          <span style="cursor: pointer;">关于我们</span>
        </h2>
        <div class="aboutUsText">
          <div class="aboutUsTextone" v-for="(item, index) in aboutUsList" :key="index">
            <div style="padding: 0 88px;">
              <h2 class="aboutUsTitle">
                <img v-if="item.title === '关于腾讯云西安子公司'" src="../../assets/images/cloundlogo.png" alt="">
                <img v-if="item.title === '关于腾讯安全云鼎实验室'" src="../../assets/images/cloundlogo.svg" alt="">
                <img v-if="item.title === '关于云鼎攻防团队'" src="../../assets/images/cloundlogo.svg" alt="">
                {{ item.title }}
              </h2>
              <p v-for="(sitem, sIndex) in item.content" :key="sIndex">{{ sitem }} </p>
            </div>
          </div>
        </div>
      </div>
      <MyFooter></MyFooter>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  components: {
  },
  data () {
    return {
      aboutUsList: null
    }
  },
  mounted () {
    this.$api.getAboutUs(res => {
      if (res.code === 200) {
        this.aboutUsList = res.data
      }
    })
  }
}
</script>

<style lang="less" scoped>
.aboutUs{
}
.aboutUsMain{
  width: 1200px;
  margin: 0 auto;
}
.aboutUsContent{
  margin-top: 77px;
  padding: 30px 82px;
  background-image: linear-gradient(226deg, #0C1E4F 0%, rgba(11,27,68,0.00) 100%);
  h2{
    font-size: 12px;
    color: rgba(138,157,207,0.80);
    margin-bottom: 93px;
    span{
      color: #FFFFFF;
    }
    i{
      font-size: 20px;
      margin: 0 5px;
      position: relative;
      top: 2px;
    }
  }
}
.aboutUsText{
  width: 1000px;
  margin: 0 auto;
  // background: blueviolet;
  .aboutUsTextone{
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(78,101,255,0.40);
    &:last-child{
      border-bottom: none;
    }
    p{
        color: rgba(230,237,255,0.80);
        line-height: 22px;
        margin-bottom: 30px;
    }
    .aboutUsTitle{
      font-size: 30px;
      color: #FFFFFF;
      margin: 67px 0 28px 0;
      img{
        width:59px;
        height:42px;
        margin-right: 27px;
      }
    }
  }
}
</style>
