<template>
<div class="MyHeaderWrap">
  <div class="matrixLogo">
    <img @click="$router.push('/home')" src="../../assets/images/yundinglaboratory.png" alt="" style="width:210px;height:25px;cursor: pointer;">
  </div>
  <div class="navText">
      <span @click="jumpInfoList" style="margin-right:44px;cursor: pointer;">
        <a href='/info/list.html' style="text-decoration:none;color: #fff;">每日云安全资讯</a>
      </span>
      <span @click="$router.push('/MatrixDoc')" style="margin-right:44px;cursor: pointer;">
        <a href='/MatrixDoc' style="text-decoration:none;color: #fff;">矩阵文档</a>
      </span>
      <span @click="$router.push('/AboutUs')" style="cursor: pointer;">
        <a href='/AboutUs' style="text-decoration:none;color: #fff;">关于我们</a>
      </span>
  </div>
</div>
</template>

<script>
export default {
  name: 'MyHeader',
  methods: {
    jumpInfoList: function (event) {
      window.location.href = '/info/list.html'
    }
  }
}
</script>

<style lang="less" scoped>
.MyHeaderWrap{
  display: flex;
  justify-content: space-between;
  padding-top: 33px;
  position: relative;
  z-index: 1;
}
.navText{
  font-weight: Medium;
  color: #FFFFFF;
}
.matrixLogo{
  padding-left:32px;
  font-weight: Medium;
  color: #FFFFFF;
  font-size: 16px;

}
</style>
