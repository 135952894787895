// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/common.css'
import '@/assets/js/axios.js'
import App from './App'
import api from '@/api/index'
import router from './router'
import store from './store'
import Meta from 'vue-meta'

// 全局注册组件
import myComponents from './components'
store.state.data = myData
Vue.use(myComponents)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Meta)

Vue.prototype.$api = api// 这是接口数据
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  // 预渲染
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
